<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Semua Perusahaan </CCol>
              <CCol md="8" class="text-right"> </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Tgl Dari</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="start"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Tgl Sampai</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="end"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md" v-if="this.session_role_id == 1">
                    <div class="form-group">
                      <b for="">Nama User</b>
                      <v-select
                        :options="list_user"
                        label="name"
                        v-model="user_filter"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-5 d-flex justify-content-start"
                    style="margin-bottom: 10px; gap: 10px"
                  >
                    <div
                      class="btn btn-sm btn-primary"
                      v-on:click="searchMaster"
                    >
                      <i class="fa fa-filter"></i> Filter
                    </div>
                    <div class="btn btn-sm btn-success" v-on:click="getExcel">
                      <i class="fa fa-download"></i> Excel
                    </div>
                    <div
                      class="btn btn-sm btn-danger"
                      v-on:click="getPDFLink()"
                    >
                      <i class="fa fa-download"></i> {{ buttonLabel }}
                    </div>
                  </div>
                </div>
              </div>

              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <template>
                      <div v-if="show_tables">
                        <table class="table table-sm table-bordered">
                          <thead class="bg-dark text-white">
                            <tr>
                              <th>No</th>
                              <th>Nama</th>
                              <th>Role</th>
                              <th>Waktu Aktifitas</th>
                              <th>Aktifitas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template>
                              <tr v-if="loadingTable">
                                <td colspan="6">
                                  <i class="fa fa-spin fa-refresh"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="dataintable.length == 0">
                                <td colspan="6">Data Belum Tersedia</td>
                              </tr>
                              <tr
                                v-for="(
                                  row_data, key_perusahaan
                                ) in dataintable"
                                :key="key_perusahaan"
                                v-else
                              >
                                <td>
                                  <template>
                                    <div v-if="key_perusahaan + 1 == 10">
                                      {{ curent_page }}0
                                    </div>
                                    <div v-else>
                                      {{
                                        curent_page - 1 != 0
                                          ? curent_page - 1
                                          : ""
                                      }}{{ key_perusahaan + 1 }}
                                    </div>
                                  </template>
                                </td>
                                <td>{{ row_data.name }}</td>
                                <td>{{ row_data.role_nama }}</td>
                                <td>{{ row_data.tanggal_kegiatan }}</td>
                                <td>{{ row_data.kegiatan }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                        <nav aria-label="pagination">
                          <ul class="pagination justify-content-start">
                            <!---->
                            <!-- {{ page.active ? 'active' : '' }} -->
                            <li
                              v-for="(page, key_page) in paging"
                              :key="key_page"
                              v-bind:class="{ active: page.active }"
                              class="page-item"
                            >
                              <div
                                v-on:click="toPage(page.url)"
                                class="page-link c-page-link-number"
                              >
                                <div v-html="page.label"></div>
                              </div>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div v-else>
                        <div class="alert alert-info">
                          Untuk menampilkan data, filter data terlebih dahulu
                        </div>
                      </div>
                    </template>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
    const dataintable = [];
    export default {
        name: "LogAktifitas",
        components: {
            vuejsDatepicker
        },
        data() {
            return {
                dataintable: [],
                paging: [],
                show_data_to: '',
                show_data_from: '',
                total_data: '',
                curent_page: '',
                loadingTable: true,
                searchData: '',
                list_daerah: [],
                provinsi_selected: [],
                kota_selected: [],
                excel_url: '',
                start: '',
                end: '',
                list_user: '',
                user_filter: '',
                show_tables: false,
                session_role_id: JSON.parse(this.session).roles_id,
                session_data: JSON.parse(this.session),
      buttonLabel: "Export PDF",
      isDownloading: false,
            }
        },
        methods: {
            getExcel(){
				// alert(this.excel_url);
				// return false;
				var win = window.open(this.excel_url, '_blank');
				if (win) {
					//Browser has allowed it to be opened
					win.focus();
				} else {
					//Browser has blocked it
					alert('Please allow popups for this website');
				}
			},
            toPage(url) {
                this.loadingTable = true;
                axios.get(url, {
                    
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_perusahaan = response.data;
                    var res_perusahaan_data = res_perusahaan.data.list;
                    if (res_perusahaan.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_perusahaan.data.message
                        });
                    }
                    else{
                        this.loadingTable = false;
                        this.dataintable = res_perusahaan_data.data;
                        this.paging = res_perusahaan_data.links;
                        this.show_data_from = res_perusahaan_data.from;
                        this.show_data_to = res_perusahaan_data.to;
                        this.total_data = res_perusahaan_data.total;
                        this.curent_page = res_perusahaan_data.current_page;
                        this.excel_url = res_perusahaan.data.excel_url;
                        console.log(this.dataintable);
                    }
                });
            },
            
            searchMaster(){
                this.loadingTable = true;
                if(this.session_role_id != 1){
                    this.user_filter = this.session_data;
                }
                
                if (!this.start) {
                    Swal.fire({
                        icon: "error",
                        text: 'Harap Filter Data Terlebih Dahulu',
                    });
                    return false;
                }
                this.show_tables = true;
                axios
                .get(this.apiLink + "api/crud/log_aktifitas", {
                    params: {
                        users_id: this.user_filter?.id,
                        start: this.start,
                        end: this.end
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_perusahaan = response.data;
                    var res_perusahaan_data = res_perusahaan.data.list;
                    // console.log(res_perusahaan_data);
                    if (res_perusahaan.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_perusahaan.data.message,
                        });
                    } else {
                        this.loadingTable = false;
                        this.dataintable = res_perusahaan_data.data;
                        this.paging = res_perusahaan_data.links;
                        this.show_data_from = res_perusahaan_data.from;
                        this.show_data_to = res_perusahaan_data.to;
                        this.total_data = res_perusahaan_data.total;
                        this.curent_page = res_perusahaan_data.current_page;
                        this.excel_url = res_perusahaan.data.excel_url;
                        console.log(this.dataintable);
                    }
                });
    },

    getPDFLink() {
      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "Laporan SIP.pdf";

      axios
        .get(this.apiLink + "api/pdf_log_aktifitas", {
          params: {
            users_id: this.user_filter?.id,
            start: this.start,
            end: this.end,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
  },
        beforeCreate() {
            axios.get(this.apiLink + "api/master/users", {
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_daerah = response.data;
                var res_daerah_data = res_daerah.data;
                // console.log(res_daerah_data);
                if (res_daerah.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_daerah.data.message
                    });
                }
                else{
                    this.list_user = res_daerah_data.master;
                }
            });
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
        }
    };
</script>